import React from "react";


import "./index.css"

export const Footer = () => {

  return (
    <div className="footer-main">
      <p className="header">Copyright &copy; bhandarideepakdev@Gmail.com</p>
     
    </div>
  )
}